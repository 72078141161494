body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #0746a5;
}

a:hover {
  color: #4d84d8;
}

.logo {
  margin-left: 30px;
  float: left;
  margin-top: 8px;
}

.logo > a {
  color: #4d9ddb;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 40pt;
}

.head_panel {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 80px;
  border-bottom: solid 2px #e2e2e2;
  box-shadow: 0 0px 5px rgba(0.2, 0.2, 0.2, 0.1);
  background-color: rgb(32, 32, 32);
}

.head_panel_right {
  display: block;
  width: inherit;
  height: 100%;
}

.menu_item>a {
  display: block;
  line-height: normal;
  text-decoration: none;
  color: rgb(216, 216, 216);
  font-size: 16pt;
  text-align: center;
  padding: 4px;
}

.menu_item>a:hover {
  color: rgb(255, 255, 255);
}

.menu_item {
  display: flex;
  align-items: center;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 100%;
  height: calc(100% - 2px);
}

.active_item {
  background-color: #2e2e2e;
  border-bottom: solid 2px #008fff;
}

.menu_item:hover {
  background-color: #2e2e2e;
}

.menu {
  float: right;
  height: 100%;
  margin-right: 30px;
}

.content {
  margin-top: 120px;
  font-family: 'Inter', sans-serif;
  width: 1024px;
  margin-left: calc(50% - 512px);
}

.article_title {
  font-size: 28pt;
  font-weight: 600;
}

.article_title > a {
  color: #000;
  text-decoration: none;
}

.article_meta {
  font-size: 15pt;
  color: rgb(97, 134, 158);
  margin-top: 4px;
  margin-bottom: 16px;
  /* margin-left: 4px; */
}

.article_snippet {
  font-size: 19px;
  /* margin-left: 4px; */
  line-height: 25px;
}

.separator {
  padding-left: 10px;
  padding-right: 10px;
}

.error_view {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
}

.error_code {
  color: #3684c1;
  font-size: 120pt;
  margin-top: 60px;
  width: 100%;
  text-align: center;
}

.error_msg {
  color: #202020;
  font-size: 30pt;
  text-align: center;
}

.error_back {
  color: #1b6dac;
  font-size: 25pt;
  padding: 40px;
  cursor: pointer;
}

.error_back:hover {
  color: #2781c5;
}

.lang {
  color: #b9b9b9;
  margin-left: 4px;
  text-decoration: none;
  font-size: 12pt;
}

.lang:hover {
  color: #ffffff;
}

.active_lang {
  color: #e6e6e6;
}

.icon > svg {
  width:  26px;
  height:  26px;
}